import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const StyledWrapper = styled(Container)`
  padding: 80px 0 0;

  ${media.lg.min} {
    padding: 80px 30px 0 30px;
  }
`

const StyledHeading = styled.h3`
  padding: 0 36px;
  font-size: 26px;

  ${media.lg.min} {
    padding: 0;
    font-size: 38px;
  }
`

const StyledBenefitWrapper = styled.div`
  margin-top: 40px;

  ${media.lg.min} {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
  }
`

const StyledImageWrapper = styled.div`
  height: 260px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${media.lg.min} {
    width: 50%;
    height: 550px;
  }
`

const StyledContentWrapper = styled.div`
  padding: 40px 36px 45px 36px;
  background-color: ${({ theme }) => theme.colors.midnightgreen};
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.min} {
    width: 50%;
    padding: 80px 65px 0 50px;
    display: flex;
    gap: 25px;
  }

  ${media.xl.min} {
    padding: 80px 125px 0 100px;
  }
`

const StyledNumber = styled.p`
  font-size: 30px;
  font-weight: 600;

  ${media.lg.min} {
    font-size: 40px;
    line-height: 45px;
  }
`

const StyledInfoWrapper = styled.div``

const StyledTitle = styled.p`
  margin: 15px 0 25px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;

  ${media.lg.min} {
    margin: 0;
    font-size: 30px;
    line-height: 45px;
  }
`

const StyledText = styled.p`
  line-height: 22px;

  ${media.lg.min} {
    margin-top: 25px;
    font-size: 18px;
    line-height: 24px;
  }
`

const StyledLocation = styled.div`
  margin-top: 15px;
  display: flex;
  p {
    margin-left: 20px;
  }
`

const StyledBenefitsWrapper = styled.div`
  ${StyledBenefitWrapper}:nth-of-type(2) {
    ${StyledContentWrapper} {
      background-color: ${({ theme }) => theme.colors.sunray};
      order: -1;
    }
  }
  ${StyledBenefitWrapper}:nth-of-type(3) {
    ${StyledContentWrapper} {
      background-color: ${({ theme }) => theme.colors.oldrose};
    }
  }
`

type Props = {
  heading: string
  benefits: Array<{
    image: {
      src: ImageDataLike
      alt: string
    }
    heading: string
    text: string
  }>
}

const CooperationBenefits: React.FC<Props> = ({ heading, benefits }) => {
  return (
    <StyledWrapper>
      <StyledHeading dangerouslySetInnerHTML={{ __html: heading }} />
      <StyledBenefitsWrapper>
        {benefits.map(({ image, heading: title, text }, index) => (
          <StyledBenefitWrapper>
            <StyledImageWrapper>
              <LazyImage src={image.src} alt={image.alt} fill />
            </StyledImageWrapper>
            <StyledContentWrapper>
              <StyledNumber>{`0${index + 1}`}</StyledNumber>
              <StyledInfoWrapper>
                <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
                <StyledText dangerouslySetInnerHTML={{ __html: text }} />
              </StyledInfoWrapper>
            </StyledContentWrapper>
          </StyledBenefitWrapper>
        ))}
      </StyledBenefitsWrapper>
    </StyledWrapper>
  )
}

export default CooperationBenefits
