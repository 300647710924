import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

import pricetagIcon from 'assets/icons/pricetag-icon.svg'
import projectIcon from 'assets/icons/project-icon.svg'
import settingsIcon from 'assets/icons/settings-icon.svg'

const StyledWrapper = styled.div`
  padding: 40px 0 60px 0;
  background-color: ${({ theme }) => theme.colors.midnightgreen};
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;

  ${media.lg.min} {
    padding: 39px 0 36px 0;
    flex-direction: row;
    justify-content: center;
    gap: 80px;
  }
`

const StyledAdvantageItem = styled.div`
  display: flex;
`

const StyledIcon = styled.img``

const StyledText = styled.p`
  margin-left: 17px;
  font-family: 'Merriweather';
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  ${media.lg.min} {
    margin-left: 15px;
  }
`

const CooperationAdvantages: React.FC<{ advantages: string[] }> = ({
  advantages,
}) => {
  const getAdvantageIcon = (advantage: string): string => {
    switch (advantage) {
      case 'Niższe ceny':
        return pricetagIcon
      case 'Wyjątkowe usługi dodatkowe':
        return projectIcon
      case 'Wsparcie techniczne':
        return settingsIcon
      default:
        return settingsIcon
    }
  }

  return (
    <StyledWrapper>
      {advantages.map((advantage) => (
        <StyledAdvantageItem>
          <StyledIcon src={getAdvantageIcon(advantage)} />
          <StyledText>{advantage}</StyledText>
        </StyledAdvantageItem>
      ))}
    </StyledWrapper>
  )
}

export default CooperationAdvantages
