import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledContactInfo = styled.div`
  background-color: ${({ theme }) => theme.colors.pewterblue};
  padding: 40px 36px;

  ${media.lg.min} {
    padding: 120px 0 120px calc((100vw - 1396px) / 2);
    ${media.xxl.max} {
      padding: 120px 30px;
    }
  }
`

type Props = {
  text: string
  title: string
  phone: string
  email: string
}

const Contact: React.FC<Props> = ({ text, title, phone, email }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContactInfo>
      <Text
        size={lg ? 20 : 14}
        themecolor="white"
        margin="20px"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <Text
        as="h2"
        size={lg ? 34 : 24}
        weight={700}
        themecolor="white"
        margin="60px"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Text size={lg ? 26 : 18} themecolor="white" margin="15px" weight={600}>
        Telefon
      </Text>
      <Text
        as="a"
        href={`tel:${phone}`}
        themecolor="white"
        margin="45px"
        size={lg ? 24 : 16}
        dangerouslySetInnerHTML={{ __html: phone }}
      />

      <Text size={lg ? 26 : 18} themecolor="white" margin="15px" weight={600}>
        Email
      </Text>
      <Text
        as="a"
        href={`mailto:${email}`}
        themecolor="white"
        margin="45px"
        size={lg ? 24 : 16}
        dangerouslySetInnerHTML={{ __html: email }}
      />
    </StyledContactInfo>
  )
}

export default Contact
