import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import SideBySide from 'components/shared/side-by-side'

import Header from 'components/header'
import CooperationAdvantages from 'components/pages/cooperation/cooperation-advantages'
import About from 'components/about'
import ContactInfo from 'components/pages/cooperation/contact-info'
import ContactForm from 'components/contact-form'
import CooperationBenefits from 'components/pages/cooperation/cooperation-benefits'

import { WspolpracaPageQuery } from 'types/graphql'

const Wspolpraca: React.FC<PageProps<WspolpracaPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.cooperationPage
  const PAGE_SEO = data?.wpPage?.seo!

  const ADVANTAGES = PAGE?.cooperationPageS1List?.map(
    (item) => item?.cooperationPageS1ListElementTitle!
  )

  const BENEFITS =
    PAGE?.cooperationPageS4List?.map((benefit) => ({
      image: {
        src: benefit?.cooperationPageS4ListElementImg?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: benefit?.cooperationPageS4ListElementImg?.altText!,
      },
      heading: benefit?.cooperationPageS4ListElementTitle!,
      text: benefit?.cooperationPageS4ListElementParagraph!,
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <Header
        image={{
          src: PAGE?.cooperationPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.cooperationPageS1Img?.altText!,
        }}
        title={PAGE?.cooperationPageS1Title!}
        subtitle={PAGE?.cooperationPageS1Paragraph!}
        page="współpraca"
      />
      <CooperationAdvantages advantages={ADVANTAGES!} />
      <About
        image={{
          src: PAGE?.cooperationPageS2Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.cooperationPageS2Img?.altText!,
        }}
        title={PAGE?.cooperationPageS2Title!}
        text={PAGE?.cooperationPageS2Paragraph!}
        buttonText="Współpracuj z nami"
        buttonLink="#kontakt"
      />
      <CooperationBenefits
        heading={PAGE?.cooperationPageS4Title!}
        benefits={BENEFITS!}
      />
      <div id="kontakt">
        <SideBySide addMarginTop>
          <ContactInfo
            text={PAGE?.cooperationPageS3Paragraph!}
            title={PAGE?.cooperationPageS3Title!}
            phone={PAGE?.cooperationPageS3Phone!}
            email={PAGE?.cooperationPageS3Email!}
          />
          <ContactForm />
        </SideBySide>
      </div>
    </Layout>
  )
}

export default Wspolpraca

export const query = graphql`
  query WspolpracaPage {
    wpPage(slug: { regex: "/wspolpraca/" }) {
      seo {
        ...WpSEO
      }
      cooperationPage {
        cooperationPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        cooperationPageS1Title
        cooperationPageS1Paragraph
        cooperationPageS1List {
          cooperationPageS1ListElementTitle
        }
        cooperationPageS2Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        cooperationPageS2Title
        cooperationPageS2Paragraph
        cooperationPageS3Paragraph
        cooperationPageS3Title
        cooperationPageS3Phone
        cooperationPageS3Email
        cooperationPageS4Title
        cooperationPageS4List {
          cooperationPageS4ListElementTitle
          cooperationPageS4ListElementParagraph
          cooperationPageS4ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`
