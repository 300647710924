import React from 'react'
import styled from 'styled-components'

import { Heading, Text } from 'components/shared/typography'
import Container from 'components/shared/container'
import Button from 'components/shared/button'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import scrollToSection from 'utils/scrollToSection'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const StyledWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightgray};
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    height: 750px;
    margin-bottom: 60px;
  }
`

const StyledContentWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 60px;

  ${media.lg.min} {
    padding-top: 125px;
    width: 40%;
    padding-bottom: 0;
    padding-right: 50px;
  }

  a {
    display: block;
    text-align: center;
    ${media.lg.min} {
      width: fit-content;
    }
  }
`

const ImageWrapper = styled.div`
  height: 300px;

  ${media.lg.min} {
    position: absolute;
    right: 0;
    top: 60px;
    height: 750px;
    width: 57%;
  }
`

type AboutProps = {
  image: {
    src: ImageDataLike
    alt: string
  }
  title: string
  text: string
  buttonText?: string
  buttonLink?: string
}

const About: React.FC<AboutProps> = ({
  image,
  title,
  text,
  buttonText,
  buttonLink,
}) => {
  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <Container>
        <StyledContentWrapper>
          <Heading
            as="h1"
            dangerouslySetInnerHTML={{ __html: title }}
            size={lg ? 34 : 20}
            sizeDiff={0}
            margin={lg ? 30 : 25}
          />
          <Text
            dangerouslySetInnerHTML={{ __html: text }}
            size={lg ? 20 : 16}
            line="27px"
            margin={buttonLink ? '60px' : 0}
          />
          {buttonText && buttonLink && (
            <>
              {buttonLink.startsWith('#') ? (
                <Button onClick={() => scrollToSection(buttonLink)}>
                  {buttonText}
                </Button>
              ) : (
                <Button
                  as="a"
                  href={buttonLink}
                  target={buttonLink?.includes('http') ? '_blank' : '_self'}
                >
                  {buttonText}
                </Button>
              )}
            </>
          )}
        </StyledContentWrapper>
      </Container>
      <ImageWrapper>
        <LazyImage src={image.src} alt={image.alt} fill />
      </ImageWrapper>
    </StyledWrapper>
  )
}

export default About
